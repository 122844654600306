<template>
    <layout>
        <div class="w-100">
            <div class="row justify-content-center">
                <div class="col-10 col-sm-7 col-md-5 col-lg-8 col-xl-7 col-xxl-6 login-wrapper py-5">
                    <div class="mx-auto login-w-limit">
                        <div class="logo-wrapper text-center mb-2">
                            <img src="../../../assets/img/bau-logo-for-light.svg" height="35" />
                        </div>
                        <h4 class="text-center mb-5" style="min-height: 50px;">
                            {{ $t('turkmer') }} {{ $t('application_system') }}
                        </h4>
                        <div class="fadeIn">
                            <ValidationObserver ref="form">
                                <div class="mx-auto">
                                    <ValidationProvider name="username" rules="required" v-slot="{ errors }">
                                        <b-form-group :label="$t('national_id') + ' / ' + $t('passport_number')">
                                            <b-form-input type="text" v-model="forgotForm.username"
                                                :state="errors[0] ? false : null" />
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <ValidationProvider name="father_name" rules="required" v-slot="{ errors }">
                                        <b-form-group :label="$t('father_name')">
                                            <b-form-input v-model="forgotForm.father_name"
                                                :state="!errors[0] ? null : false" />
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <ValidationProvider name="birthdate" rules="required" v-slot="{ errors }">
                                        <b-form-group :label="$t('birthdate')">
                                            <select-date v-model="forgotForm.birthdate" :validation-error="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                    <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" size="invisible"
                                        sitekey="6LelIscaAAAAAA0ODBaTFs_FUqS3WTgy-G0bP1pG" />
                                    <div class="d-flex justify-content-center my-4">
                                        <b-button variant="primary" block @click="forgot()">
                                            {{ $t('send').toUpper() }}
                                        </b-button>
                                    </div>
                                    <a class="text-center d-block text-muted cursor-pointer" @click="goToLogin()">
                                        {{ $t('login') }}
                                    </a>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
// Layout
import Layout from '@/modules/turkmerApplication/layout/Layout.vue'
// Service
import TurkmerApplicationStudentService from '@/services/TurkmerApplicationStudentService'
// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
    name: "TurkmerForgotPassword",
    components: {
        Layout,
        ValidationObserver,
        ValidationProvider,
        VueRecaptcha
    },
    data() {
        return {
            forgotForm: {
                username: null,
                birthdate: null,
                father_name: null
            }
        }
    },
    mounted() {
        this.$store.dispatch('turkmerApplication/isLoginGoIndex');
    },
    methods: {
        async forgot() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.$refs.recaptcha.execute()
            }
        },
        onCaptchaVerified(recaptchaToken) {
            this.forgotForm.g_recaptcha_token = recaptchaToken;
            TurkmerApplicationStudentService.pinReminder(this.forgotForm)
                .then(response => {
                    if (response.data.success) {
                        this.$toast.success(this.$t('password_send_success'));
                        this.$router.push('/turkmer/login');
                    }
                })
                .catch((e) => {
                    this.showErrors(e);
                }).finally(() => {
                    this.$refs.recaptcha.reset()
                })
        },
        goToLogin() {
            this.$router.push('/turkmer/login');
        }
    }
}
</script>
